.add-button {
  width: 100%;
  aspect-ratio: 1;
  border: none;
  border-radius: 10px;
  font-size: 24px;
  font-weight: bold;
  background-color: #ddd;
  color: #333;
  cursor: pointer;
}